import React from 'react';
import { connect } from 'react-redux';
import './profile.css';
import { doGet } from '../../../modules/DataManager';

const mapStateToProps = (state) => {
    return { 
        user: state.user
    }
}

let isLoading = false;

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fatturatoMese: "0",
            viaggiMese: "0",
            fatturatoGiorno: "0",
            viaggiGiorno: "0"
        };
        this.onOpen = this.onOpen.bind(this);
    }
    onOpen() {
        const u = this.props.user;
        if(u && !isLoading) {
            isLoading = true;
            const id = u.id;
            doGet("/analytics/report-user-month", { user_id: id })
                .then((result) => {
                    this.setState({
                        fatturatoMese: result.transato.toString(),
                        viaggiMese: result.viaggi
                    })
                    isLoading = false;
                })
            doGet("/analytics/report-user-day", { user_id: id })
                .then((result) => {
                    this.setState({
                        fatturatoGiorno: result.transato.toString(),
                        viaggiGiorno: result.viaggi
                    })
                    isLoading = false;
                })
        }
    }
    render() {
        let av = "";
        if(this.props.user) {
            av = this.props.user.nome.charAt(0) + this.props.user.cognome.charAt(0);
        }
        return (
            <div id="profileMenu" onMouseOver={this.onOpen}>
                <div id="avatar">
                    {av}
                </div>
                <div id="services">
                    <div className="servicesContainer">
                        <div class="arrow-up"></div>
                        <div className='userService'>
                            OGGI. Fatt.: {this.state.fatturatoGiorno}€, Viaggi: {this.state.viaggiGiorno}
                        </div>
                        <div className='userService'>
                            MESE. Fatt.: {this.state.fatturatoMese}€, Viaggi: {this.state.viaggiMese}
                        </div>
                        <div className='userService' onClick={() => {
                            window.firebase.auth().signOut();
                        }}>
                            LOGOUT
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(Profile);