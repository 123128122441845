import React from 'react';
import * as moment from 'moment';
import './navbar.css'
import { Link } from "react-router-dom";
import i18n from "../../i18n";
import Profile from './profile';

class Navbar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        'richieste': 0,
        'soccorso': false
      };
    }
    componentDidMount() {
      const start_str = moment().format('YYYY-MM-DD') + " 00:01:00";
      const start = moment(start_str).toDate();
      let self = this;
      window.firebase.firestore().collection('sos')
        .where('report_datetime', '>', start)
        .onSnapshot(function(col) {
          if(!col.empty) { //Abbiamo delle richieste di soccorso
            self.setState({
              'richieste': col.docs.length,
              'soccorso': true
            });
          }
        })
    }
    render() {
      return (
        <nav className={"main-header navbar navbar-expand navbar-white navbar-light " + (this.state.soccorso ? i18n.t("soccorso") : '') }>
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" data-widget="pushmenu" href="#"><i className="fa fa-bars"></i></a>
            </li>
          </ul>
          <Link to="/map">{i18n.t("ci_sono")} {this.state.richieste} {i18n.t("richieste_di_soccorso")}</Link>
          <Profile />
        </nav>
      );
    }
  }
  
  export default Navbar;
  