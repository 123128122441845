import * as moment from "moment/moment";
import i18n from "../i18n";

const initialState = {
    'current_check': {},
    'company': '',
    'role': '',
    'trip': {},
    'daily_trip': {},
    'search': '',
    'dates_history': {
        'data_da': moment().add(-1, 'months').format('DD/MM/YYYY'),
        'data_a': moment().format('DD/MM/YYYY')
    },
    'begin_date_calendar': moment().format('YYYY-MM-DD') + " 00:00:01",
    'start_timeline': moment().add(-3, 'hour').toDate(),
    'end_timeline': moment().add(3, 'hour').toDate(),
    'calendarDate': null,
    'language': localStorage.getItem("i18nextLng"),
    'days': [0,1,2,3,4,5,6],
    'user': null
};

function rootReducer(state = initialState, action) {
    let new_state = state;
    if (action.type === 'SET_CURRENT_CHECK') {
        new_state = Object.assign({}, state, {
            current_check: action.payload
        });
    } else if(action.type === 'SET_COMPANY') {
        new_state = Object.assign({}, state, {
            company: action.payload
        });
    } else if(action.type === 'SET_ROLE') {
        new_state = Object.assign({}, state, {
            role: action.payload
        });
    } else if(action.type === 'SET_TRIP') {
        new_state = Object.assign({}, state, {
            trip: action.payload
        });
    } else if(action.type === 'SET_DAILY_TRIP') {
        new_state = Object.assign({}, state, {
            daily_trip: action.payload
        });
    } else if(action.type === 'SET_DATES_HISTORY') {
        new_state = Object.assign({}, state, {
            dates_history: action.payload
        });
    } else if(action.type === 'SET_BEGIN_DATE_CALENDAR'){
        new_state = Object.assign({}, state, {
            begin_date_calendar: action.payload
        });
    } else if(action.type === 'SET_SEARCH'){
        new_state = Object.assign({}, state, {
            search: action.payload
        });
    } else if(action.type === 'SET_CALENDAR_DATE'){
        new_state = Object.assign({}, state, {
            calendarDate: action.payload
        });
    } else if (action.type === 'CHANGE_LANGUAGE') {
        new_state = Object.assign({}, state, {
            'language': action.payload
        });
        i18n.changeLanguage(action.payload)
    } else if(action.type === 'SET_DAYS'){
        new_state = Object.assign({}, state, {
            days: action.payload
        });
    } else if(action.type === 'SET_GROUP_TRIP'){
        new_state = Object.assign({}, state, {
            group_trip_data: action.payload
        });
    } else if(action.type === 'SET_START_TIMELINE'){
        new_state = Object.assign({}, state, {
            start_timeline: action.payload
        });
    } else if(action.type === 'SET_END_TIMELINE'){
        new_state = Object.assign({}, state, {
            end_timeline: action.payload
        });
    } else if(action.type === 'SET_TRIPS_OF_THE_DAY'){
        new_state = Object.assign({}, state, {
            trips_of_the_day: action.payload
        });
    } else if(action.type === 'SET_PENDINGS'){
        new_state = Object.assign({}, state, {
            pendings: action.payload
        });
    } else if(action.type === 'SET_USER'){
        new_state = Object.assign({}, state, {
            user: action.payload
        });
    }
    window.sessionStorage.setItem("state", JSON.stringify(new_state))
    return new_state;
};

export default rootReducer;