import React, { Suspense } from 'react';
import Navbar from '../shared/navbar'
import Sidebar from '../shared/sidebar'
import Footer from '../shared/footer'
import Login from '../components/login'
import {
  Route,
  withRouter
} from "react-router-dom";
import { connect } from 'react-redux';
import { setCompany, setUserRole, setUser } from "../actions/index";
import './App.css';

const Home = React.lazy(() => import('../components/home'));
const Map = React.lazy(() => import('../components/map'));
const History = React.lazy(() => import('../components/history'));
const AggiungiViaggio = React.lazy(() => import('../components/home/aggiungi'));
const Mezzi = React.lazy(() => import('../components/mezzi'));
const Users = React.lazy(() => import('../components/users'));
const UsersAdd = React.lazy(() => import('../components/users/aggiungi'));
const MezziAdd = React.lazy(() => import('../components/mezzi/aggiungi'));
const Rubrica = React.lazy(() => import('../components/rubrica'));
const DettaglioRubrica = React.lazy(() => import('../components/rubrica/aggiungi'));
const Checks = React.lazy(() => import('../components/checks'));
const CheckDetails = React.lazy(() => import('../components/checks/details'));
const Daily = React.lazy(() => import('../components/giornaliere'));
const DailyDetails = React.lazy(() => import('../components/giornaliere/details'));
const Gestori = React.lazy(() => import('../components/gestori'));
const AggiungiUtenteGestore = React.lazy(() => import('../components/gestori/aggiungi_utente'));
const AggiungiGestore = React.lazy(() => import('../components/gestori/aggiungi'));
const Contratti = React.lazy(() => import('../components/contratti'));
const DettaglioContratto = React.lazy(() => import('../components/contratti/details'));
const Calendar = React.lazy(() => import('../components/home/calendar'));
const Timeline = React.lazy(() => import('../components/home/timeline'));
const Analytics = React.lazy(() => import('../components/analytics'));
const ServicesMap = React.lazy(() => import('../components/home/ServicesMap'));
const TripGroup = React.lazy(() => import('../components/home/TripGroup'));
const DailyGroup = React.lazy(() => import('../components/giornaliere/DailyGroup'));
const UsersRole = React.lazy(() => import('../components/role_users'))
const Companies = React.lazy(() => import('../components/companies'))
const CompaniesAdd = React.lazy(() => import('../components/companies/aggiungi'));
const OptimizeDT = React.lazy(() => import('../components/companies/optimize'))

const mapDispatchToProps = dispatch => {
  return {
      setCompany: (c) => dispatch(setCompany(c)),
      setUserRole: (u) => dispatch(setUserRole(u)),
      setUser: (u) => dispatch(setUser(u))
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  }
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    //this.gestoreClick = this.gestoreClick.bind(this);
  }
  componentDidMount() {
    let self = this;
    window.firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        window.firebase.firestore().collection('users').doc(user.uid).get()
          .then(function(doc) {
              let user = doc.data();
              user.id = doc.id;
              let role = 'user';
              if(user.role) {
                for(let r in user.role) {
                  const urole = user.role[r];
                  if(urole===true) {
                    role = r; 
                  }
                }
              }
              self.props.setCompany(user.company)
              self.props.setUserRole(role);
              self.props.setUser(user);
              localStorage.setItem('user', JSON.stringify(user));
              self.props.history.push("/home");
          })
      } else {
        self.props.history.push("/");
      }
    });
  }
  render() {
    return (
      <div className="wrapper">
        <Suspense fallback={<div>Loading...</div>}>
          <Navbar />
          <Sidebar />
        
          <Route exact path="/" component={Login}/>
          <Route exact path="/home" component={Home}/>
          <Route exact path="/map" component={Map}/>
          <Route exact path="/history" component={History}/>
          <Route exact path="/add-trip" component={AggiungiViaggio}/>
          <Route exact path="/edit-trip/:id" component={AggiungiViaggio}/>
          <Route exact path="/edit-group-trip/:id" component={TripGroup}/>
          <Route exact path="/mezzi" component={Mezzi}/>
          <Route exact path="/edit-mezzo/:id" component={MezziAdd}/>
          <Route exact path="/autisti" component={Users}/>
          <Route exact path="/aggiungi-autista" component={UsersAdd}/>
          <Route exact path="/modifica-autista/:id" component={UsersAdd}/>
          <Route exact path="/aggiungi-mezzo" component={MezziAdd}/>
          <Route exact path="/checks" component={Checks}/>
          <Route exact path="/checks/:id" component={CheckDetails}/>
          <Route exact path="/daily" component={Daily}/>
          <Route exact path="/daily-details" component={DailyDetails}/>
          <Route exact path="/daily-details/:id" component={DailyDetails}/>
          <Route exact path="/daily-group-details/:id" component={DailyGroup}/>
          <Route exact path="/gestori/:id" component={AggiungiGestore}/>
          <Route exact path="/gestori/user/:id" component={AggiungiUtenteGestore}/>
          <Route exact path="/gestori" component={Gestori}/>
          <Route exact path="/contratti" component={Contratti}/>
          <Route exact path="/contratti/:id" component={DettaglioContratto}/>
          <Route exact path="/rubrica/:id" component={DettaglioRubrica}/>
          <Route exact path="/rubrica" component={Rubrica}/>
          <Route exact path="/calendario" component={Calendar}/>
          <Route exact path="/timeline" component={Timeline}/>
          <Route exact path="/analytics" component={Analytics}/>
          <Route exact path="/services-map" component={ServicesMap}/>
          <Route exact path="/users-role" component={UsersRole}/>
          <Route exact path="/companies" component={Companies}/>
          <Route exact path="/add-company" component={CompaniesAdd}/>
          <Route exact path="/edit-company/:id" component={CompaniesAdd}/>
          <Route exact path="/optimize-routes-dt/:id" component={OptimizeDT}/>
          <Footer />
        </Suspense>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
