import React from 'react';
import Input from '../../shared/input';
import { connect } from 'react-redux';
import { setCompany } from "../../actions/index";
import './login.css';
import i18n from "../../i18n";

const mapDispatchToProps = dispatch => {
    return {
        setCompany: (c) => dispatch(setCompany(c))
    }
}

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'forgot_open': false,
            'username_mbility': '',
            'password_mbility': '',
            'email_recupero': ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.forgotOpen = this.forgotOpen.bind(this);
        this.forgotClose = this.forgotClose.bind(this);
        this.handleForgot = this.handleForgot.bind(this);
    }
    handleChange(e) {
        const input = e.target;
        let field = {}
        field[input.name] = input.value;
        this.setState(field);
    }
    handleSubmit(e) {
        e.preventDefault();
        let valid_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.username_mbility)
        let valid_password = this.state.password_mbility && this.state.password_mbility.length>7;
        if(valid_email) {
            if(valid_password) {
                window.firebase.auth().signInWithEmailAndPassword(this.state.username_mbility, this.state.password_mbility)
                    .catch(function(error) {
                        if(error.code=='auth/wrong-password') {
                            alert('Password errata')
                        } else if(error.code=='auth/user-not-found') {
                            alert('L\'utente non esiste')
                        } else {
                            console.log(error.code)
                        }
                    })
            } else {
                console.log("La password deve essere di almeno 8 caratteri")
            }
        } else {
            console.log("Formato email non valido")
        }
    }
    forgotOpen(e) {
        e.preventDefault();
        this.setState({
            'forgot_open': true
        })
    }
    forgotClose(e) {
        e.preventDefault();
        this.setState({
            'forgot_open': false
        })
    }
    handleForgot(e) {
        e.preventDefault();
        let self = this;
        if(this.state.email_recupero!='') {
            let valid_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email_recupero)
            if(valid_email) {
                window.firebase.auth().sendPasswordResetEmail(this.state.email_recupero).then(function() {
                    alert(i18n.t("email_inviata"));
                    self.setState({
                        'forgot_open': false
                    })
                }).catch(function(error) {
                    alert(i18n.t("errore_generico"))
                });
            } else {
                alert(i18n.t("email_non_valida"))
            }
        } else {
            alert(i18n.t("inserisci_email"));
        }
    }
    render() {
        let logoUrl = 'https://firebasestorage.googleapis.com/v0/b/mytransport-246817.appspot.com/o/frontends%2FMbility%2Fmbility.png?alt=media&token=c0cf5ed4-aea5-4f8e-8c00-5939bd3f3ba7';
        if(window.location.host=='gtp.mbility.app') {
            window.document.querySelector("body").classList.add("gtp")
            logoUrl = 'https://firebasestorage.googleapis.com/v0/b/mytransport-246817.appspot.com/o/frontends%2FGTP%2Flogo_gtp.png?alt=media&token=37da7904-9e63-4096-bd69-7c5c4c4520ad';
        } else if(window.location.host=='easy.mbility.app') {
            window.document.querySelector("body").classList.add("easy")
            logoUrl = 'https://firebasestorage.googleapis.com/v0/b/mytransport-246817.appspot.com/o/frontends%2FEasy%2Flogo_cooperativa_easy.png.webp?alt=media&token=14e8eb0e-7940-4fe9-9499-a2795874b969';
        } else {
            window.document.querySelector("body").classList.add("mbility")
        }
        return (
            <div id="login">
                <img id="logo-login" src={logoUrl} alt="Logo" />
                <div className={"login-box " + (this.state.forgot_open?'hidden':'')}>
                    <h2>
                        {i18n.t("accedi")}
                    </h2>
                    <form onSubmit={this.handleSubmit}>
                    <Input type="email" name="username_mbility" placeholder={i18n.t("email")} label="" change={this.handleChange} value={this.state.username_mbility} />
                        <Input type="password" name="password_mbility" placeholder={i18n.t("password")} label="" change={this.handleChange} value={this.state.password_mbility} />
                        <div className="row">
                            <div className="col-sm-8">
                                <a href="#" className="forgot" onClick={this.forgotOpen}>{i18n.t("password_dimenticata")}</a>
                            </div>
                            <div className="col-sm-4">
                                <button type="submit" className="btn btn-primary btn-block btn-flat">
                                    {i18n.t("entra")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className={"login-box " + (!this.state.forgot_open?'hidden':'')}>
                    <h2>
                        {i18n.t("reimposta_password")}
                    </h2>
                    <form onSubmit={this.handleForgot}>
                        <Input type="email" name="email_recupero" placeholder="Email" label="" change={this.handleChange} value={this.state.email_recupero} />
                        <div className="row">
                            <div className="col-sm-8">
                                <a href="#" className="forgot" onClick={this.forgotClose}>{i18n.t("indietro")}</a>
                            </div>
                            <div className="col-sm-4">
                                <button type="submit" className="btn btn-primary btn-block btn-flat">
                                    {i18n.t("reimposta")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(Login);